


















import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const NSDocuments = namespace('storeProjectDocuments');
const NSWorkList = namespace('storeWorkList');

import '../scss/ExecutiveDocsComposite.scss';
import { IDocumentsState, IDocument } from '@store/modules/project-documents/Interfaces';
import { ProjectDocumentsMutations } from '@store/modules/project-documents/Types';

@Component({
    name: 'ExecutiveDocsComposite',

    components: {
        WorkComposite: () => import('@pages/the-work-list/components/WorkComposite.vue'),
        ConstructPacks: () => import('@pages/the-work-list/components/ConstructPacks.vue'),
        DocumentsGroup: () => import('@pages/the-documents/components/DocumentsGroup.vue'),
    },
})

export default class ExecutiveDocsComposite extends Vue {
    @NSDocuments.Getter('compositeShow') compositeShow!: boolean;
    @NSDocuments.Getter('documentsWithoutRecursion') documentsWithoutRecursion!: IDocument[];
    @NSDocuments.State((state: IDocumentsState) => state.viewingDocument) viewingDocument!: IDocument;
    @NSDocuments.Mutation(ProjectDocumentsMutations.M_SET_VIEWING_DOCUMENT) setViewingDocument!: (document: IDocument | null) => void;
    @NSDocuments.Getter('documents') documents!: IDocument[];
    @NSWorkList.Getter('docPacks') docPacks!: any[];

    get pageTitle() {
        return this.compositeShow ? 'Структура работ' : 'Раздел ПД';
    }

    @Watch('compositeShow')
    onCompositeShowToggle() {
        if (!this.compositeShow) {
            this.setViewingDocument(null);
        }
    }

    childrenIsFolder(work) {
        return work.children.filter(item => item.isFolder);
    }
}

